import { ReactComponent as ArrowBackIcon } from 'assets/svg/ArrowBack.svg';
import { ReactComponent as ArrowSideIcon } from 'assets/svg/ArrowSide.svg';
import { InformationPoint } from "component/InformationPoint";
import Navbar from "component/Navbar";
import "./style.css";
import { PieChart, Pie, Tooltip, Legend, Cell } from 'recharts';
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { client } from 'utils/client';


// Sample data
const fatherData = [
    { name: 'Ingredient and packaging production', val: 1 },
    { name: 'Medicine production and corporate emissions', val: 1 },
    { name: 'Storage and distribution', val: 1 },
];


const colors = [
    "#283",
    "#4c6",
    "#aeb"
];


const SelectedProduct = () => {

    const theme = useTheme();
    const history = useHistory();

    const handleRedirection = () => {
        history.push('/medicine_footprint?product=' + encodeURIComponent(smallName));
    }

    const [name, setName] = useState("");
    const [smallName, setSmallName] = useState("");
    const [wiselist, setWiselist] = useState("");
    const [footPrint, setFootPrint] = useState("");
    const [maxWidth, setMaxWidth] = useState(1000);
    const containerRef = useRef<null | HTMLDivElement>(null);
    const [data, setData] = useState(
        fatherData
            .map((e,i) => {
                let newData: any = e;
                newData.fill = colors[i];
                return newData;
            })
    );

    const windowResize = () => {
        setMaxWidth((containerRef.current?.getBoundingClientRect()?.width ?? 0) - 100);
    }

    useEffect(() => {
        /**
         * Function to get the informations about a product
         */
        const getInformations = (id: number) => {
            client(`medicine/info/${id}`)
                .then(rep => {
                    setFootPrint(rep.carbon);
                    setName(rep.name);
                    setSmallName(rep.smallName);
                    setWiselist(rep.wiselist);
                    setData(prevData => {
                        // Put the new values
                        prevData[0].val = rep.emCreation;
                        prevData[1].val = rep.emProduction;
                        prevData[2].val = rep.emStorage;

                        // Update the colors
                        prevData
                            .map((e,i) => {
                                let newData: any = e;
                                newData.fill = colors[i];
                                return newData;
                            });

                        // Delay the update of the add of % since there are no callback of updates in the cells.
                        setTimeout(updatePercentage, 10);

                        // Return the data
                        return JSON.parse(JSON.stringify(prevData));
                    });
                })
                .catch(why => console.error(why));
        }

        windowResize();
        const location = window.location.href;
        const id = location.split("=")[1];
        getInformations(+id);
        window.addEventListener("resize", windowResize);
        return () => window.removeEventListener("resize", windowResize);
    }, []);


    const updatePercentage = () => {
        Array.from(document.getElementsByTagName("tspan"))
            .forEach(e => {
                if (!e.innerHTML.endsWith("%")) 
                    e.innerHTML += "%";
            })
    }


    return <div className="selected-product">
        <Navbar />
        <div ref={containerRef}>
            <button
                type="button"
                style={{ color: theme.black, }}
                onClick={handleRedirection}
                className="back-home"
            >
                <ArrowBackIcon />
                Back to results
            </button>

            <h2> Medicine carbon footprint database </h2>
            <h4> Selected product </h4>

            <p>
            { name }
            </p>

            <div className="info" style={{maxWidth: Math.min(680, maxWidth - 130) + "px"}}>
                <span>
                    <ArrowSideIcon className="arrow-side"/> Cradle-to-gate carbon footprint: {footPrint} gCO₂eq/box <br/>
                </span>
                <div>
                    <InformationPoint
                        text={`Cradle-to-gate carbon footprint until delivery to pharmacy. Does not take into account storage in pharmacies, use phase and end-of-life.`}
                    />
                </div>
                <span>
                <ArrowSideIcon className="arrow-side"/> Type of evaluation: estimation done by Ecovamed (<a href="https://ecovamed-front-document.s3.eu-west-3.amazonaws.com/methodology.pdf" target="_blank" rel="noreferrer">Methodology</a>)
                </span>
                <div>
                    <InformationPoint
                        text={`The evaluation is either done by Ecovamed as an estimation based on public and non-confidential data (see methodology) or is done by the pharmaceutical company from primary data, and has been reviewed by Ecovamed`}
                    />
                </div>
            </div>

            {
                data.some(e => e.val !== 1) &&
                <PieChart width={maxWidth} height={300 + (maxWidth > 650 ? 0 : 50)} style={{backgroundColor: "#0000"}}>
                    <Pie
                        isAnimationActive={false}
                        data={data.map(el => {
                                const tot = data.map(e => e.val).reduce((a,b) => a + b, 0)
                                return {
                                    ...el,
                                    val: +(100 * el.val / tot).toFixed(1)
                                }
                            })
                        }
                        dataKey="val"
                        outerRadius={100}
                        fill="green"
                        innerRadius={30}
                        label
                    >
                        {data.map((entry, index) => (
                            <Cell
                                className={["first", "second", "third"][index]}
                                key={`cell-${index}`}
                                onMouseOver={(e) => e.currentTarget.setAttribute("filter", "brightness(1.15)")}
                                onMouseLeave={(e) => e.currentTarget.setAttribute("filter", "brightness(1)")}
                            />
                        ))}
                    </Pie>
                    <Tooltip formatter={s => `${s}%`} labelFormatter={s => `${s}%`}/>
                    {
                      maxWidth > 650 ?
                        <Legend  align="right" layout="vertical" verticalAlign="middle" /> :
                        <Legend/>
                    }
                </PieChart>
            }

            <div className="info no-i">
                <p>
                <ArrowSideIcon className="arrow-side"/> <a href={wiselist} target="_blank" rel="noreferrer">Click</a> for more information on Environmental risk during use. Persistence, Bioaccumulation and Toxicity of the API
                </p>
                <p>
                    <ArrowSideIcon className="arrow-side"/> <a href="mailto:contact@ecovamed.com">Contact</a> Ecovamed for any questions or specific study request
                </p>
            </div>

            <p className="more-info">
            This carbon footprint assessment has been established exclusively based on public data and/or public scientific studies, non confidential and reusable, assuming them to be reliable and established on the basis of relevant information. Ecovamed does not provide any guarantee as to the accuracy, completeness, relevance and timeliness of these results and data. These carbon footprints are based on the Life Cycle Assessment methodology, whose specific implementation to these products has been submitted for evaluation to the scientific community. Ecovamed cannot be held responsible for any consequences of the exploitation or use of these evaluations.
            </p>
        </div>
    </div>
}

export default SelectedProduct;
