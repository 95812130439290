/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Navbar from '../../component/Navbar';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowBackIcon } from 'assets/svg/ArrowBack.svg';
import DeleteDialog from 'component/DeleteDialog';
import { useEffect, useRef, useState } from 'react';
import "./style.css";
import Input from 'component/Input';
import Select from 'component/Select';
import { InformationPoint } from 'component/InformationPoint';
import { client } from 'utils/client';

interface IProduct {
    name: String;
    carbon: String;
    id: number;
}

const TableProducts = ({ products, redir }: { products: IProduct[], redir: (id: number) => void }) => {

    return <table
        css={{
            borderCollapse: "collapse",
            "th, td": {
                border: "1px solid #216",
                textAlign: 'left',
                paddingBlock: 12,
                paddingInline: 15,
                fontWeight: 500,
                fontSize: 14,
            },
            thead: {
                background: "#216",
                color: "#fff",
                textAlign: 'left',
            },
            a: {
                color: "inherit",
                "&:hover": {
                    textDecoration: "underline"
                }
            },
            svg: {
                transform: "rotate(180deg) translateX(10px)"
            }
        }}
    >
        <thead>
            <tr>
                <th> Name of the products </th>
                <th> gCO₂eq/box</th>
                <th> </th>
            </tr>
        </thead>
        <tbody>
            {
                products
                .map(prod =>
                    <tr>
                        <td> <a href={`/medicine_footprint/product?id=${prod.id}`}>{ prod.name }</a> </td>
                        <td css={{ minWidth: 150 }}> { prod.carbon } </td>
                        <td css={{maxWidth: 30, cursor: "pointer", minWidth:30}} onClick={() => redir(prod.id)}>
                            <ArrowBackIcon />
                        </td>
                    </tr>
                )
            }
        </tbody>
    </table>;
}




function MedicineFootprint() {
    const theme = useTheme();
    const history = useHistory();
    const [deleteAction, setDeleteAction] = useState<null | (() => void)>(null);
    // const [selectedCountry, setSelectedCountry] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [products, setProducts] = useState<IProduct[]>([]);
    const [autoCompletes, setAutoCompletes] = useState([]);
    const [searchError, setSearchError] = useState<null | string>(null);
    const inputRef = useRef<null | HTMLInputElement>(null);

    const redirectToProduct = (id: number) => {
        history.push(`/medicine_footprint/product?id=${id}`);
    }

    useEffect(() => {
        const queryParams = (new URL("" + document.location)).searchParams;

        // Access individual query parameters
        const product = queryParams.get('product');

        console.log(product, queryParams)
        if (product !== null) {
            // Get the products
            client(`medicine/list/${product}`)
                .then(rep => {
                    setAutoCompletes([]);
                    setProducts(rep);
                    setSearchError(null);
                    setTimeout(() => {
                        console.log(products);
                    }, 100);
                })
                .catch(why => {
                    console.warn(why)
                    setAutoCompletes([]);
                    setSearchError("error");
                });
        }
    }, []);

    useEffect(() => {
        if (searchTerm)
            client(`medicine/complete/${searchTerm}`)
                .then(rep => {
                    setAutoCompletes(rep);
                })
                .catch(why => console.error(why));
    }, [searchTerm]);

    const searchProducts = (description: string) => {
        // Update the ref
        if (inputRef.current !== null && inputRef.current !== undefined) {
            inputRef.current.value = description ?? "";
        }

        // Get the products
        client(`medicine/list/${description}`)
            .then(rep => {
                setAutoCompletes([]);
                setProducts(rep);
                setSearchError(null);
            })
            .catch(why => {
                console.warn(why)
                setAutoCompletes([]);
                setSearchError("error");
            });
    }

    const handleRedirection = () => {
        history.push('/home');
    }
    const close = () => setDeleteAction(null);

    return (
        <div className="medicineFootprint">
            <DeleteDialog
                close={close}
                isOpen={deleteAction ? true : false}
                action={deleteAction}
            />
            <div css={{ backgroundColor: theme.white, color: theme.black, }}>
                <Navbar />
                <div css={{ backgroundColor: theme.white, }}>
                    <button
                        type="button"
                        css={{ color: theme.black, }}
                        onClick={handleRedirection}
                    >
                        <ArrowBackIcon />
                        Back Home
                    </button>

                    <h2>Medicine carbon footprint database</h2>

                    <p css={{ color: theme.black, }}>Identify your product</p>
                    <div css={{ display: 'flex', marginBottom: '16px' }}>
                        <Input
                            // onChange={(data: any) => setSelectedCountry(data)}
                            // options={["France"]}
                            disabled={true}
                            label="Country of commercialization"
                            name="country"
                            placeholder="France"
                            // getOptionLabel={(option: any) => option.label}
                            // value={selectedCountry}
                            // isClearable={true}
                            // isDisable={true}
                            css={{
                                maxWidth: '450px',
                            }}
                            register={() => {}}
                            inputRef={null}
                        />
                        <InformationPoint
                            css={{
                                marginTop: '40px',
                                minHeight: 30,
                                marginLeft: '16px',
                            }}
                            text={`Only France is available so far, please contact us if you would like to have any other countries`}
                        />
                    </div>

                    <div css={{ display: 'flex', marginBottom: '16px', position: "relative" }}>
                        <Input
                            label="Name*"
                            placeholder={`Ibuprofen`}
                            registerOption={{ required: `Name can't be empty` }}
                            name="reportDataContactPersonEmail"
                            register={() => {}}
                            css={{
                                maxWidth: "450px",
                            }}
                            onInput={(e: KeyboardEvent) => setSearchTerm((e.currentTarget as any)?.value ?? "") }
                            inputRef={inputRef}
                        />
                        <InformationPoint
                            css={{
                                marginTop: '40px',
                                minHeight: 30,
                                marginLeft: '16px',
                            }}
                            text={`You can search the name of the Active Pharmaceutical Ingredient or the name of the Pharmaceutical Specialty (commercial name)`}
                        />
                        {
                            autoCompletes.length > 0 &&
                            <ul css={{ position: "absolute", top: 70, backgroundColor: "#eee", padding: 10, maxHeight: 300, overflow: "auto" }}>
                            {
                                autoCompletes.map(e =>
                                    <li
                                        css={{ listStyle: "none", padding: 0, cursor: "pointer", "&:hover": {textDecoration: "underline"}  }}
                                        onClick={() => searchProducts(e)}
                                    >
                                        { e }
                                    </li>
                                )
                            }
                            </ul>
                        }
                    </div>
                    <br/>
                    {
                        searchError ?
                            <div css={{color: "#f35", backgroundColor: "#f354", fontWeight: 500, borderRadius: 5, paddingInline: 13, paddingBlock: 8}}>
                                The number of requests is limited to 30 per day and 100 per month. Please contact us if you need more results.
                        </div> :
                        (
                            products.length === 0 ?
                            <span>
                                <br/>
                                As of today, only medicines in tablet and capsule forms are available in the database.
                                Other forms will be added in the future
                            </span> :

                            <>
                            <h5 css={{ fontWeight: 700, marginBottom: 8}}>To get more information, you can click on product's name</h5>
                                <TableProducts products={products} redir={redirectToProduct}/>
                                <p className="more-info">
                                    This carbon footprint assessment has been established exclusively based on public data and/or public scientific studies, non confidential and reusable, assuming them to be reliable and established on the basis of relevant information. Ecovamed does not provide any guarantee as to the accuracy, completeness, relevance and timeliness of these results and data. These carbon footprints are based on the Life Cycle Assessment methodology, whose specific implementation to these products has been submitted for evaluation to the scientific community. Ecovamed cannot be held responsible for any consequences of the exploitation or use of these evaluations.
                                </p>
                            </>
                        )
                    }
                </div>
                {/* <Input */}
                {/*     label="Other step*" */}
                {/*     placeholder="..." */}
                {/*     register={() => {}} */}
                {/*     registerOption={{ required: `Other step can't be empty` }} */}
                {/*     name="otherStep" */}
                {/*     css={{ */}
                {/*         maxWidth: '400px', */}
                {/*     }} */}
                {/* /> */}
            </div>
        </div>
    );
}

    export default MedicineFootprint;
